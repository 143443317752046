@import 'variables';

.standings-page {

    .no-standings {
        font-size: $text-medium;
        font-weight: 600;
    }

    .select-prompt {
        font-size: $text-medium;
        text-align: center;
        width: 85%;
        margin: 0 auto;
    }

    .divisions {
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: $breakpoint-l) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;

            .modal-button {
                width: 33%;
            }
        }
    }
}