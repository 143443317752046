@import 'variables';

.content {
    background-color: $background-light;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - $footer-height - (2 * $footer-padding));
}

.about-page,
.announcement-page,
.contact-page,
.error-page,
.gallery-page,
.games-page,
.login-page,
.register-page,
.results-page,
.rules-page,
.standings-page,
.waiver-upload-page,
.dashboard-page,
.sponsors-page,
.edit,
.manage {
    margin-top: $navbar-height;

    .page-title {
        font-size: $page-title-size;
        font-weight: 600;
        padding: 1rem 0;
        text-align: center;
        word-wrap: break-word;
    }
}

.footer {
    margin-top: auto;
}