.sponsors-page {

    .main-sponsors,
    .secondary-sponsors {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        width: 80%;
        max-width: 15rem;
        margin: 0 auto;
    }

    .main-sponsors {
        .sponsor {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    @media only screen and (min-width: 600px) {

        .main-sponsors,
        .secondary-sponsors {
            max-width: 30rem;
        }

        .main-sponsors {
            .sponsor {
                width: 48%;
            }
        }
    }

    @media only screen and (min-width: 900px) {

        .main-sponsors,
        .secondary-sponsors {
            max-width: 55rem;
        }

        .main-sponsors {
            .sponsor {
                width: 24%;
            }
        }
    }
}