@import 'variables';

.announcement-card {
    padding: 0.5rem;
    background-color: $white;
    border-radius: 0.35rem;

    .title {
        font-size: $text-large;
        font-weight: 600;
    }

    .date-info {
        margin: 0.1rem 0 1.25rem 0;
    }

    .announcement-body {
        margin-top: 1rem;
    }

    // Markup-Markdown styling
    .wmde-markdown-color {
        // background: none;
        background-color: $white;
        color: inherit;
        font-family: inherit;
        padding: 0.5rem;
    }
}