@import 'variables';

.no-announcement-message {
    text-align: center;
    font-weight: 600;
}

.announcement {
    margin: 2rem auto;
    width: 90%;
}

// Media queries
@media only screen and (min-width: $breakpoint-l) {
    .announcement-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .announcement {
            width: 31%;

            .announcement-card {
                height: 35rem;
                overflow: auto;
            }
        }
    }
}