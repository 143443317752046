@import 'variables';

.rules-splash {
    // min-height: calc(100vh - $navbar-height);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    text-align: center;
    padding: 3rem;

    .page-title {
        height: 30%;
    }

    .rule-directory {
        height: 70%;

        .directory-prompt {
            font-weight: 600;
            font-size: $text-medium;
        }

        .directory-buttons {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-around;
            font-size: $text-medium;

            button {
                border: none;
                background-color: transparent;
                padding: 0.5rem;
                max-width: 12rem;
            }

            @media only screen and (min-width: $breakpoint-m) {
                flex-direction: row;
                max-width: 45rem;

                button {
                    width: 50%;
                    max-width: none;
                }
            }
        }
    }
}

.rule-container {
    padding: 2rem;
    background-color: $psa-blue-200;

    &>div {
        // Adds scroll offset to compensate for header
        scroll-margin-top: 64px;
        background-color: $white;
        padding: 1rem;
        border-radius: 0.35rem;

        &:not(:first-child) {
            margin-top: 2rem;
        }
    }

    .rule-title {
        text-align: center;
        font-size: $text-large;
        font-weight: 600;
    }

    .rule-details {
        margin-top: 0.5rem;
    }
}