@import 'variables';

.register-section-selection {
    text-align: center;
    margin: 1rem 0;

    .MuiTabs-flexContainer {
        justify-content: space-around;
    }
}

.registration-text {
    padding: 0 1rem;
    word-wrap: break-word;

    .waiver-info {
        margin-top: 1rem;
        background-color: $psa-blue-500;
        color: $white;
        padding: 0.65rem;
        border-radius: 0.75rem;

        a {
            color: $white;
        }

        p:not(:first-child) {
            margin-top: 1rem;
        }
    }

    @media only screen and (min-width: 600px) {
        margin: 0 auto;
        max-width: 40rem;
    }

    @media only screen and (min-width: 900px) {
        max-width: 55rem;
    }
}

// Team info section
.team-name-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 80%;
    margin: 0 auto;

    input {
        text-align: center;
        background-color: $white;
        border: none;
        border-radius: 0.5rem;
        font-size: 2rem;
        margin-bottom: 0.25rem;
    }
}

.season-section {
    margin-top: 1.5rem;
    font-size: 1.5rem;

    select {
        text-align: center;
        border-radius: 0.35rem;
        padding: 0.25rem;
        width: 80%;
        display: block;
        margin: 0 auto;
    }
}

// Player info section
.player-details {
    text-align: center;
    width: 80%;
    max-width: 35rem;
    margin: 0 auto;

    h2 {
        font-weight: 600;
        font-size: $text-medium;
    }

    .remove-player-button {
        border: none;
        background-color: transparent;
        margin: 0.25rem 0;
        font-weight: 600;
        color: $red-500;
    }

    .leader-section,
    .players-section {
        margin-top: 2rem;

        h4 {
            font-weight: 600;
        }

        .name-section,
        .birthday-section,
        .phone-number-section,
        .birthdate-section,
        .email-section {
            margin-bottom: 1rem;
        }

        .player-input-section {
            margin-bottom: 3rem;

            h3 {
                font-weight: 600;
            }
        }

        .birthdate-input,
        .phone-number-section input,
        .email-section input,
        .name-inputs {
            border-radius: 0.35rem;
            border: 1px solid $black;
            border-radius: 0.35rem;
            padding: 0.35rem;
        }

        .name-section,
        .birthday-section,
        .email-section,
        .phone-number-section {
            display: flex;
            flex-direction: column;
        }

        .birthdate-input {
            width: 100%;
            text-align: center;
        }

        .name-inputs {
            display: flex;
            flex-direction: column;

            background-color: $white;

            input {
                border: none;
                background: transparent;
            }

            @media only screen and (min-width: $breakpoint-m) {
                flex-direction: row;
                justify-content: space-between;

                input {
                    width: 50%;
                }
            }
        }
    }
}



.add-player-button {
    margin: 2rem auto 0 auto;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-weight: 600;
    border: none;
    border-radius: 0.5rem;
    background-color: $psa-blue-500;
    color: $white;

    svg {
        fill: $white;
        width: 2rem;
        height: auto;
    }

    &:disabled {
        background-color: $neutral-300;

        &:hover {
            background-color: $neutral-300;
        }
    }

    &:hover {
        background-color: $psa-blue-700;
    }
}

.max-players-message {
    font-weight: 600;
    color: $red-500;
}

.submit-team-button {
    display: block;
    margin: 2rem auto;
    background-color: $psa-blue-500;
    color: $white;
    border: none;
    border-radius: 0.35rem;
    padding: 0.5rem;
    font-weight: 600;

    &:hover {
        background-color: $psa-blue-700;
    }
}

// Submitted Registration display
.submitted-display {
    width: 95%;
    max-width: 45rem;
    margin: 0 auto;
    padding: 1rem;

    h2 {
        font-weight: 600;
    }

    p {
        margin-top: 1.5rem;
    }

    .important-alert {
        font-weight: 600;
    }
}