// Colours
$black: #000;
$white: #FFF;
// Background
$background-light: #FAFAFA;
// Branding
$psa-blue-100: #E1F0FA;
$psa-blue-200: #A5D5F2;
$psa-blue-300: #91D0FA;
$psa-blue-400: #57B7F7;
$psa-blue-500: #1D96EC;
$psa-blue-600: #0C7EC9;
$psa-blue-700: #0E71B3;
$psa-blue-800: #0F6299;
$psa-blue-900: #032842;
// Greens
$green-100: #D6F2CE;
$green-300: #7FE858;
$green-500: #3DDB0D;
$green-700: #319C11;
$green-900: #123806;
// Reds
$red-100: #F7DCDF;
$red-300: #FA8E99;
$red-500: #E01B2F;
$red-700: #7A1F28;
$red-900: #3B0207;
// Yellow
$yellow-100: #FAF4D9;
$yellow-300: #E8D67B;
$yellow-500: #F5D120;
$yellow-700: #BFA315;
$yellow-900: #5C4F08;
// Neutrals
$neutral-100: #DAE0E3;
$neutral-300: #B1C2C9;
$neutral-500: #5F6B70;
$neutral-700: #474D52;
$neutral-900: #232424;

$navbar-height: 4rem;
$footer-height: 3rem;
$footer-padding: 1rem;

$page-title-size: 3rem;

$text-large: 2rem;
$text-medium: 1.5rem;

// Breakpoints
$breakpoint-s: 300px;
$breakpoint-m: 600px;
$breakpoint-l: 900px;

// Animation variables
$animation-speed: 400s;