@import 'variables';

.flavour-text,
.file-upload-section {
    width: fit-content;
    max-width: 80%;
    margin: 0 auto;
}

.flavour-text {
    font-weight: 600;
}

.file-upload-section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.image-upload {
    background-color: $white;
    border: 0.1rem solid black;
    border-radius: 0.5rem;
}

.upload-button {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    background-color: $psa-blue-500;

    .upload-icon,
    p {
        fill: $white;
        color: $white;
    }

    p {
        margin-left: 0.5rem;
        font-weight: 600;
    }

    &:disabled {
        background-color: $neutral-300;

        .upload-icon,
        p {
            fill: $neutral-100;
            color: $neutral-100;
        }
    }
}