.image-gallery {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 90%;
    max-width: 22.5rem;
    align-items: center;

    .image-container {
        width: 100%;
        height: 80%;
        margin: 0.5rem 0;

        img {
            border-radius: 0.35rem;
        }
    }

    @media only screen and (min-width: 600px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 40rem;

        .image-container {
            width: 49%;
        }
    }

    @media only screen and (min-width: 900px) {
        max-width: 60rem;

        .image-container {
            width: 33%;
        }
    }
}