@import 'variables';

.navbar {
    background-color: $white;

    .logo {
        height: 3rem;
        width: auto;
        fill: $black;
        transition: 0.1s;

        &:hover {
            fill: lighten($black, 20%);
        }
    }

    .nav-link {
        color: $black;
        transition: 0.1s;
        padding: 0.5rem;
        font-weight: 600;

        &:hover {
            color: lighten($black, 20%);
        }
    }
}

.drawer {
    background-color: $background-light;

    .drawer-logo {
        margin: 0.5rem 0;
        height: 3rem;
        width: auto;
        fill: $black;
        transition: 0.1s;

        &:hover {
            fill: lighten($black, 20%);
        }
    }

    .drawer-item {
        width: 100%;
    }

    button {
        width: 100%;
    }

    .nav-link-dropdown {
        width: 100%;
        text-align: center;
        color: $black;
        text-decoration: none;
    }
}