@import 'variables';

.contact-body {
    width: 85%;
    max-width: fit-content;
    margin: 0 auto;

    form {
        margin-top: 2rem;
    }

    .contact-name,
    .contact-email,
    .contact-message {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            font-weight: 600;
        }

        input,
        textarea {
            border: 0.1rem solid $black;
            border-radius: 0.25rem;
            padding: 0.25rem;
        }
    }

    .contact-button {
        display: block;
        margin: 2.5rem auto 0 auto;
        padding: 0.5rem 2rem;
        border: none;
        border-radius: 0.25rem;
        background-color: $psa-blue-500;
        color: $white;
        font-weight: 600;

        &:hover {
            background-color: $psa-blue-700;
        }
    }
}