@import 'variables';

.management-link {
    text-decoration: none;
    padding: 0.25rem 1rem;
    margin: 0 auto;
    width: fit-content;
    max-width: 80%;
    display: block;
    color: $white;
    background-color: $psa-blue-500;
    border-radius: 0.5rem;
    word-wrap: break-word;
    font-weight: 600;

    &:hover {
        background-color: $psa-blue-700;
        color: $white;
    }
}

.user-input {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 50rem;
    margin: 1rem auto;

    label {
        font-weight: 600;
    }

    input,
    select {
        width: 100%;

        &:not([type='checkbox']) {
            border: 0.1rem solid $black;
            border-radius: 0.35rem;
            padding: 0.25rem;
        }
    }
}

.button-container {
    display: flex;
    flex-direction: column;
    width: 80%;

    margin: 2rem auto;

    button {
        padding: 0.25rem 1rem;
        margin: 0.25rem 0;
        border-radius: 0.5rem;
        border: none;
        color: $white;
        font-weight: 600;
    }

    .save-button {
        background-color: $green-500;

        &:hover {
            background-color: $green-700;
        }
    }

    .clear-button {
        color: $black;
        background-color: $yellow-500;

        &:hover {
            background-color: $yellow-700;
        }
    }

    .delete-button {
        background-color: $red-500;

        &:hover {
            background-color: $red-700;
        }
    }

    @media only screen and (min-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
        max-width: 15rem;
    }
}

// Specific styling for user inputs with checkboxes
.complete-section {
    display: flex;
    flex-direction: row;
    max-width: 12.5rem;
    word-wrap: break-word;

    input {
        flex: 1;
    }

    label {
        flex: 2;
    }
}

// Additional styling for team editing page

.players-section {

    .leader-title,
    .players-title {
        font-size: $text-medium;
    }

    .player-inputs {
        margin-bottom: 3rem;
    }

    .player-name {
        background-color: $white;
        border: 0.1rem solid $black;
        border-radius: 0.5rem;
        padding: 0.25rem;
        display: flex;
        flex-direction: column;

        input {
            border: none;
            background-color: transparent;
        }

        @media only screen and (min-width: 600px) {
            flex-direction: row;

            input {
                width: 50%;
            }
        }
    }

    .eligibility-input-section,
    .email-input-section,
    .phone-input-section,
    .birthday-input-section {
        margin-top: 0.5rem;
    }

    .delete-player-button {
        border: none;
        border-radius: 0.5rem;
        padding: 0.25rem 1rem;
        display: block;
        margin: 0.5rem auto;
        color: $white;
        background-color: $red-500;
        font-weight: 600;

        &:hover {
            background-color: $red-700;
        }
    }

    .eligibility-input-section {
        .eligibility-groups {
            display: flex;
            flex-direction: column;

            .eligibility-game {
                input {
                    width: fit-content;
                }
            }
        }

        @media only screen and (min-width: 600px) {
            .eligibility-groups {
                flex-direction: row;
                justify-content: space-between;
                max-width: 20rem;
                margin: 0 auto;
            }
        }
    }
}

// Additional styling for registration editing page
.registration-season-section {
    display: flex;
    flex-direction: column;
}

.edit-registrations-page {
    .button-container {
        flex-direction: column;
        max-width: 20rem;

        button {
            margin-top: 0.5rem;
        }
    }
}

// Additional styling for announcement editing page
.announcement-editor {
    width: 90%;
    max-width: 75rem;
    margin: 0 auto;
}