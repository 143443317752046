@import 'variables';

.logout-button {
    display: block;
    margin: 0 auto;
    padding: 0.25rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: $red-500;
    color: $white;
    font-weight: 600;

    &:hover {
        background-color: $red-700;
    }
}

.dashboard-sections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2.5rem auto;
    width: 95%;
    max-width: 15rem;

    div {
        padding: 0.5rem;
        word-wrap: break-word;

        h2 {
            font-weight: 600;
            font-size: $text-medium;
        }

        &:not(:first-child) {
            margin-top: 1rem;
        }

        p {
            margin-top: 0.25rem;

            a {
                color: $psa-blue-500;

                &:hover {
                    color: $psa-blue-700;
                }
            }
        }
    }

    @media only screen and (min-width: 600px) {
        width: 90%;
        max-width: 50rem;
        flex-direction: row;

        div {
            width: 33%;
        }
    }
}