@import 'variables';

.modal-button {
    border: none;
    // background-color: lighten(red, 45%);
    background-color: transparent;
    padding: 1rem;
    font-size: $text-medium;
    font-weight: 600;
    margin: 0.25rem 0;
    transition: 0.15s;
}