@import 'variables';

.footer {
    background-color: $white;
    height: $footer-height;
    padding: $footer-padding;

    .social-media-links {
        display: flex;
        justify-content: space-around;
        max-width: 7.5rem;
        margin: 0 auto;

        svg {
            width: 2.5rem;
            height: auto;
        }

        .facebook-link:hover {
            fill: #1877F2;
        }

        .instagram-link:hover {
            fill: #E1306C;
        }
    }

    .psa-links {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 1rem 0;
        font-weight: 600;

        a {
            color: $black;
            text-decoration: none;
            transition: 0.1s;

            &:hover {
                color: lighten($black, 20%);
            }
        }

        @media only screen and (min-width: $breakpoint-s) {
            text-align: right;
        }
    }
}

@media only screen and (min-width: $breakpoint-s ) {
    .link-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .social-media-links {
            margin: 0;
            max-width: none;
            width: 5.5rem;
            justify-content: space-between;
        }
    }
}