.game-type-selection {
    margin-bottom: 2.5rem;

    .game-type-prompt {
        text-align: center;
        font-weight: 600;
    }

    .MuiTabs-flexContainer {
        justify-content: space-around;
    }
}