@import 'variables';

.dashboard-link,
.add-link,
.delete-all-button,
.upload-section button {
    display: block;
    width: fit-content;
    margin: 0 auto;
    color: $white;
    font-weight: 600;
    padding: 0.25rem 1rem;
    border: none;
    border-radius: 0.5rem;
    text-decoration: none;

    &:hover {
        color: $white;
    }
}

.dashboard-link {
    background-color: $psa-blue-500;
    margin-bottom: 1rem;

    &:hover {
        background-color: $psa-blue-700;
    }
}

.add-link {
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: $green-500;

    &:hover {
        background-color: $green-700;
    }
}

.data-table {
    width: 90%;
    margin: 0 auto;
}

.delete-all-button {
    margin: 1rem auto;
    background-color: $red-500;

    &:hover {
        color: $white;
        background-color: $red-700;
    }
}

// Image management
.upload-section {
    margin: 0 auto;
    width: fit-content;
    display: flex;
    flex-direction: column;

    button {
        width: fit-content;
        display: block;
        margin: 0.5rem auto;
        background-color: $green-500;

        &:disabled {
            background-color: $neutral-500;
        }

        &:hover:not(:disabled) {
            background-color: $green-700;
        }
    }
}