@import 'variables';

.hero-section {
    margin-top: 4rem;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;

    .hero-container {
        width: 100%;
    }

    p {
        margin: 0;
    }

    .psa-logo {
        width: 75%;
        max-width: 450px;
        height: auto;
        fill: $psa-blue-700;
    }

    .sponsor-banner {
        margin-top: 1rem;
        padding: 0.5rem 0;
        background-color: $psa-blue-700;

        h2,
        .sponsor-logo,
        a {
            color: $white;
            fill: $white;
        }

        h2 {
            font-size: $text-medium;
            font-weight: 600;
        }

        .sponsor-logo {
            width: 10rem;
            height: auto;
        }

        a {
            font-weight: 600;
        }

        a:hover {
            color: $neutral-300;
        }

        .scroller {
            margin: 0 auto;
            max-width: 50rem;
            // border-block: 0.1rem solid $black;
            // border: 3px solid lime;

            &[data-animated="true"] {
                overflow: hidden;
                -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
                mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);

                .scroller-inner {
                    width: max-content;
                    flex-wrap: nowrap;
                    animation: scroll $animation-speed linear infinite;

                    // &:hover {
                    //     animation-play-state: paused;
                    // }
                }
            }
        }

        .scroller-inner {
            padding-block: 1rem;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }

        @keyframes scroll {
            to {
                transform: translate(-100%);
            }
        }
    }
}

.home-title {
    width: fit-content;
    margin: 0 auto;
    font-size: $text-large;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}

// Specific width styling for announcement title word-wrapping on mobile devices
.announcements-section .home-title {
    width: 100%;
}

.view-all-link {
    display: block;
    width: fit-content;
    margin: 0 auto;
}

.announcements-section,
.potw-section,
.games-section,
.results-section,
.register-prompt-section {
    width: 85%;
    max-width: 75rem;
    margin: 3rem auto;
}

.announcements-section {
    .home-announcement {
        margin-top: 1rem;
    }

    @media only screen and (min-width: 600px) {
        .announcement-items {
            display: flex;
            justify-content: space-between;

            .home-announcement {
                width: 49%;
            }
        }
    }
}

.potw-section {
    .carousel {
        aspect-ratio: 1/1;
        margin: 0 auto;
        max-width: 40rem;
        overflow: hidden;
        border-radius: 0.5rem;

        img {
            width: 100%;
        }
    }
}

.register-prompt-section {

    h3,
    a {
        text-align: center;
        font-weight: 600;
    }

    h3 {
        font-size: $text-large;
    }

    a {
        display: block;
        margin-top: 1rem;
    }

    .register-link {
        text-decoration: none;
        background-color: $psa-blue-500;
        color: $white;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;

        &:hover {
            background-color: $psa-blue-700;
        }
    }
}

// Temporary season info picture
.splash-section {
    img {
        width: 90%;
        max-width: 25rem;
        border-radius: 0.5rem;
        margin: 0 auto;
        display: block;
    }
}