@import 'variables';

.MuiDialogContent-root img {
    width: 100%;
    border-radius: 0.25rem;
}

.image-container img {
    width: 100%;
}

.image-delete-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    color: $white;
    background-color: $red-500;

    &:hover {
        background-color: $red-700;
    }
}