@import 'variables';

.no-games {
    text-align: center;
    font-size: $text-medium;
    font-weight: 600;
}

.game-date {
    text-align: center;
    font-weight: 600;
    font-size: $text-medium;
}

.game-card {
    text-align: center;
    margin: 1rem 0;

    .game-display {
        width: 85%;
        padding: 0.5rem;
        border: none;
        background-color: $white;

        .game-title {
            font-size: $text-medium;
            word-wrap: break-word;

            .title-team-one,
            .title-team-two {
                font-weight: 600;
            }
        }

        .game-score {
            display: flex;
            justify-content: center;
            margin: 0.25rem 0;
            font-size: $text-medium;

            .score-component {
                width: 3rem;
            }
        }

        .game-win {
            color: $psa-blue-500;
        }
    }

    .game-details {
        width: 85%;
        padding: 0.5rem;
        margin: 0 auto;
        background-color: $white;
    }

    .show-hide-details {
        background-color: $psa-blue-500;
        border-radius: 0.25rem;
        color: $white;
        margin: 0 auto;
        width: 70%;
        max-width: 10rem;

        &:hover {
            background-color: $psa-blue-700;
        }
    }
}

// Media queries
@media only screen and (min-width: $breakpoint-m) {

    .game-title {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        max-width: 40rem;

        .title-team-one,
        .vs,
        .title-team-two {
            width: 33%;
        }
    }
}